
  import { Component } from 'vue-property-decorator'
  import Cell from '@/components/dataTables/cell/cell'
  import { Entity } from '@/entities'
  import { ExpandCellOptions } from '@/components/dataTables/cell/index'
  import InspectionSummaryView from '@/components/dataTables/inspection/InspectionSummaryView.vue'
  import { Inspection } from '@/entities/purchase'
  import { capitalize, fixPrice } from '@/utils/general'
  import GAlert from '@/components/core/alert/GAlert.vue'

@Component({
  methods: { fixPrice, capitalize },
  components: { GAlert, InspectionSummaryView },
})
  export default class InspectionSummaryCell extends Cell {
  declare item: Entity
  declare options: ExpandCellOptions

  openDialog = false
  openNotQualify = false

  alert = {
    title: '¿Está seguro que desea marcar la inspección como no califica?',
    subtitle: '',
  }

  get icon () {
    const { options } = this
    return options?.action?.icon
  }

  get color () {
    const { options } = this

    return options?.action?.color
  }

  get canClose () {
    const { options } = this

    return options?.action?.close
  }

  openSummary () {
    this.openDialog = true
  }

  get auto () {
    const { item } = this
    const { auto } = item as Inspection
    return auto
  }

  get inspection () {
    const { item } = this

    return item as Inspection
  }

  get iconInspection () {
    const { inspection } = this

    return inspection.closingReason?.icon
  }

  get colorInspection () {
    const { inspection } = this

    return inspection.closingReason?.color
  }

  get isSupervisorMetadata () {
    const { inspection } = this

    return inspection.supervisorMetadata?.totalAmount
  }

  closeInspection () {
    console.log('closeInspection')
  }

  get disabledButton () {
    const { inspection } = this

    return inspection?.status?.name === 'pending'
  }

  inspectionStatus (inspection) {
    if (inspection?.closingReason?.id) {
      return inspection.closingReason.description
    }

    return inspection?.status?.description
  }
  }
